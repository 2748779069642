<template>
  <v-container>
    <v-card max-width="500" class="mx-auto">
      <v-col>
        <view-as-user-selection @onUserSelection="onUserSelection" />
      </v-col>
      <v-row>
        <v-col cols="12" class="text-center">
          <h1>{{ $t("tasks.my-today") }}</h1>
          <h3>{{ formatDate }} - {{ dayTxt }}</h3>
        </v-col>
      </v-row>

      <v-row v-if="noCoordinateClients.length > 0">
        <v-col cols="10" class="mx-auto">
          <v-alert :value="true" type="error">
            {{ $t("general.missing-addreses-coordinates") }}:
            <ul>
              <li v-for="(client, index) in noCoordinateClients" :key="index">
                <v-icon color="error">warning</v-icon>
                {{ client.clientName }}
              </li>
            </ul>
          </v-alert>
        </v-col>
      </v-row>
      <!-- START incidents for review -->
      <template v-if="incidentsForReview.length > 0">
        <v-row
          v-for="(incident, incidentIndex) in incidentsForReview"
          :key="incidentIndex"
        >
          <v-col cols="10" class="mx-auto">
            <review-incident-alert :incData="incident" :incId="incident.id" />
          </v-col>
        </v-row>
      </template>
      <!-- END incidents for review -->
      <template
        v-if="
          !getBfhTypeServices().includes(service) &&
          !progressBar &&
          selectedUser
        "
      >
        <log-visits
          :clients="todaysClients"
          @savingVisit="savingVisit"
          :selectedUser="selectedUser"
          :plannedShifts="getAllVisits()"
        />
        <my-visits
          :clients="todaysClients"
          :plannedShifts="getAllVisits()"
          :selectedUserId="selectedUser.userId"
          :addingNewVisit="isSavingVisit"
        />
      </template>
      <!-- START If there are no tasks today -->
      <template v-if="!haveTasks && !progressBar">
        <v-card-title class="headline">
          <v-row justify="center">
            {{ $t("tasks.no-tasks-today") }}
          </v-row>
        </v-card-title>
        <v-row justify="center">
          <custom-button
            @click="refreshBrowser"
            :text="$t('btn.refresh-browser')"
            icon="refresh"
            dynamicClass="my-4"
          />
        </v-row>
      </template>
      <!-- END If there are no tasks today -->
      <template v-if="haveTasks && !progressBar">
        <v-row justify="center">
          <h2>{{ $t("tasks.tasks") }}</h2>
        </v-row>
        <v-row justify="center">
          <v-switch
            v-model="showAlsoCompletedTasks"
            inset
            @change="toggleClosed()"
            :label="$t('tasks.closed')"
          ></v-switch>
        </v-row>

        <virtual-list
          :data-key="'id'"
          :data-sources="taskItemList"
          :data-component="itemComponent"
          :page-mode="true"
          :estimate-size="500"
        />
      </template>
      <one-task-dialog
        :dialogTaskData="dialogTaskData"
        :dialogTaskId="dialogTaskId"
        :showDialog="dialogTaskShow"
        :source="dialogTaskSource"
        :index="dialogTaskIndex"
        @closeTaskDialog="dialogTaskShow = false"
        @updTaskFromDialog="updTaskFromDialog"
      />
      <loading-data
        v-if="progressBar"
        :feedbackText="$t('tasks.load-tasks')"
      ></loading-data>
    </v-card>
  </v-container>
</template>

<script>
import VirtualList from "vue-virtual-scroll-list";
import { db, auth, functions } from "@/main";
import ViewAsUserSelection from "@/components/ViewAsUserSelection.vue";
import { formatDateInRiga } from "@/helpers/dayjsDates";
import {
  daysToTxt,
  nbhShiftsToTxt,
  bfhShiftsToTxt,
  repositioningTaskId,
  bfhTypeServices,
} from "@/helpers/constants";
import ReviewIncidentAlert from "@/components/utilities/ReviewIncidentAlert.vue";
import LoadingData from "@/components/utilities/LoadingData.vue";
import CustomButton from "@/components/CustomButton.vue";
import TaskTile from "@/views/mytasks/TaskTile.vue";
import OneTaskDialog from "./OneTaskDialog.vue";
import LogVisits from "@/views/mytasks/Visits.vue";
import {
  concat,
  filter,
  find,
  findIndex,
  forEach,
  isEmpty,
  isEqual,
  isNil,
  split,
} from "lodash";
import MyVisits from "./MyVisits.vue";
import "firebase/auth";
import AlertForSurvey from "@/components/AlertForSurvey.vue";

export default {
  name: "my-tasks",
  components: {
    "virtual-list": VirtualList,
    LogVisits,
    ViewAsUserSelection,
    ReviewIncidentAlert,
    LoadingData,
    CustomButton,
    OneTaskDialog,
    MyVisits,

    AlertForSurvey,
  },
  data() {
    return {
      progressBar: false,
      selectedUser: this.$root.currentUserData,
      formatDate: formatDateInRiga(new Date()),
      incidentsForReview: [],
      taskItemList: [],
      showAlsoCompletedTasks: false,
      userData: null,
      itemComponent: TaskTile,
      dialogTaskData: {},
      dialogTaskSource: {},
      dialogTaskId: "",
      dialogTaskShow: false,
      dialogTaskIndex: 0,
      isSavingVisit: false,
      service: "bfh",
      todaysShifts: [],
      todaysClients: [],
      noCoordinateClients: [],
      allTasks: [],
      showSurvey: true,
    };
  },

  computed: {
    haveTasks() {
      return this.todaysShifts.length > 0;
    },
    dayTxt() {
      const translatedDays = daysToTxt();
      return translatedDays[new Date().getDay()];
    },
    // isBfhAider() {
    //   return this.selectedUser.aiderServices.includes("bfh");
    // },
  },
  async created() {
    // await this.getIncidentsForReview();

    await this.getUserInfo();
    await this.getTasks(
      this.selectedUser.userId,
      this.selectedUser.aiderServices
    );
    this.createTableItems();
    this.$on("openDialog", (taskId, source, index) => {
      this.dialogTaskSource = Object.assign({}, source);
      const foundTask = find(this.allTasks, (task) => isEqual(task.id, taskId));
      this.dialogTaskData = foundTask;
      this.dialogTaskId = taskId;
      this.dialogTaskIndex = index;
      this.dialogTaskShow = true;
    });
  },
  methods: {
    getBfhTypeServices() {
      return bfhTypeServices;
    },
    async onSurveyOpen() {
      this.showSurvey = false;
      let userResponse = await db
        .collection("users")
        .doc(this.selectedUser.userId)
        .get();
      if (userResponse.exists) {
        let userData = userResponse.data();
        let allSurveys = userData.surveys || {};
        let satisfaction202302Surveys = allSurveys.satisfaction202302 || [];
        satisfaction202302Surveys.push(new Date());
        allSurveys["satisfaction202302"] = [...satisfaction202302Surveys];
        this.selectedUser.surveys = allSurveys;
        await db.collection("users").doc(this.selectedUser.userId).update({
          surveys: allSurveys,
        });
      }
      setTimeout(() => {
        this.showSurvey = true;
      }, 1000);
    },
    async getTasks(selectedUserId, aiderServices) {
      let prep = functions.httpsCallable("getTodaysShiftsData");
      let request = {
        userId: selectedUserId,
        aiderServices: aiderServices,
      };

      let response = await prep(request);
      const data = response.data;

      this.todaysClients = data.todaysClients || [];
      this.service = data.service || "bfh";
      this.todaysShifts = data.allShifts || [];
      this.noCoordinateClients = data.noCoordinateClients || [];
      this.allTasks = data.allTasks || [];
    },
    updateTaskFromDialog(taskId, field, newValue) {
      let foundTaskIndex = findIndex(this.allTasks, (task) =>
        isEqual(task.id, taskId)
      );
      if (field && foundTaskIndex > -1) {
        this.allTasks[foundTaskIndex][`${field}`] = newValue;
        forEach(this.todaysShifts, (shift) => {
          forEach(shift.shiftsList, (shiftListItem) => {
            forEach(shiftListItem.shiftTasks, (shiftTask) => {
              if (isEqual(shiftTask.id, taskId)) {
                shiftTask[`${field}`] = newValue;
                if (isEqual(field, "status")) {
                  shiftTask["closedAt"] = new Date();
                }
              }
            });
          });
        });
      }
    },
    savingVisit(status) {
      this.isSavingVisit = status;
    },
    getAllVisits() {
      let visitsList = [];
      forEach(this.todaysShifts, (shift) => {
        forEach(shift.shiftsList, (shiftItem) => {
          visitsList.push(shiftItem);
        });
      });
      return visitsList;
    },
    toggleClosed() {
      this.createTableItems();
    },
    async updTaskFromDialog(taskId, field, newValue) {
      this.progressBar = true;
      this.updateTaskFromDialog(taskId, field, newValue);
      this.createTableItems();
      this.progressBar = false;
    },
    async onUserSelection(user) {
      if (user) {
        this.progressBar = true;
        this.selectedUser = user;
        this.selectedUser.userId = user.id;
        await this.getUserInfo();
        await this.getIncidentsForReview();
        await this.getTasks(
          this.selectedUser.userId,
          this.selectedUser.aiderServices
        );
        this.createTableItems();
        this.progressBar = false;
      }
    },
    async getIncidentsForReview() {
      let incidentsResponse = await db
        .collection("incidents")
        .where("createdBy", "==", this.selectedUser.userId)
        .where("reviewReviewedByAider", "==", false)
        .orderBy("updatedAt")
        .get();
      let incidentsList = [];
      incidentsResponse.docs.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        incidentsList.push(data);
      });
      this.incidentsForReview = incidentsList;
    },
    refreshBrowser() {
      window.location.reload();
    },
    createTableItems() {
      bfhTypeServices.includes(this.service)
        ? this.createTableItemsForBfhClients()
        : this.createTableItemsForOtherClients();
    },
    createTableItemsForOtherClients() {
      let listL = [];
      let tasksByClientsIds = {};
      this.allTasks.forEach((task) => {
        let taskClientId = task.clientId;
        if (isNil(tasksByClientsIds[taskClientId])) {
          tasksByClientsIds[taskClientId] = [];
        }
        tasksByClientsIds[taskClientId].push(task);
      });
      const sortedClients = [...this.todaysClients];
      sortedClients.sort((a, b) =>
        a.clientName.localeCompare(b.clientName, "lv")
      );
      sortedClients.forEach((client) => {
        let clientTasks = tasksByClientsIds[client.id];
        listL.push({
          type: "client-title",
          data: client.clientName,
          id: client.id,
        });
        if (isEmpty(clientTasks) || isNil(clientTasks)) {
          listL.push({
            type: "no-tasks",
            id: `${client.id}-empty`,
          });
        } else {
          //START Get repositioning tasks
          let repositioningTasks = filter(clientTasks, (task) =>
            isEqual(task.lpTaskId, repositioningTaskId)
          );
          forEach(repositioningTasks, (task) => {
            const hours = task.order.substring(0, 2);
            const minutes = task.order.substring(3, 5);
            const [day, month, year] = split(task.lpTaskFormatDate, ".");
            const taskCreatedDate = new Date(
              year,
              month - 1,
              day,
              hours,
              minutes
            );
            task.taskCreatedDate = taskCreatedDate;
          });
          repositioningTasks.sort(function (a, b) {
            return new Date(a.taskCreatedDate) - new Date(b.taskCreatedDate);
          });
          //END Get repositioning tasks
          //START Sort regular tasks
          const regularTasks = filter(
            clientTasks,
            (task) => !isEqual(task.lpTaskId, repositioningTaskId)
          );
          regularTasks.sort(function (a, b) {
            let [aHoursString, aMinutesString] = a.order.split(":");
            let [bHoursString, bMinutesString] = b.order.split(":");
            let aMinutes =
              parseInt(aHoursString) * 60 + parseInt(aMinutesString);
            let bMinutes =
              parseInt(bHoursString) * 60 + parseInt(bMinutesString);
            return aMinutes - bMinutes;
          });
          //END Sort regular tasks
          let completedTasksCount = 0;
          const allClientTasksList = concat(repositioningTasks, regularTasks);
          allClientTasksList.sort(
            (a, b) => a.lpTaskShiftNumber - b.lpTaskShiftNumber
          );
          forEach(allClientTasksList, (task) => {
            if (task.status !== "open") {
              completedTasksCount = completedTasksCount + 1;
            }
          });
          if (
            isEqual(allClientTasksList.length, completedTasksCount) &&
            !this.showAlsoCompletedTasks &&
            allClientTasksList.length > 0
          ) {
            listL.push({
              type: "all-tasks-completed",
              id: `${client.clientId}-completed`,
            });
          } else {
            forEach(allClientTasksList, (task) => {
              if (isEqual(task.status, "open") || this.showAlsoCompletedTasks) {
                listL.push({
                  type: "task",
                  data: task,
                  id: task.id,
                  userData: this.userData || null,
                  taskDescription: task.taskDescription || null,
                  viewDialog: false,
                  shiftName: nbhShiftsToTxt[task.lpTaskShiftNumber],
                });
              }
            });
          }
        }
      });
      this.taskItemList = listL;
    },
    createTableItemsForBfhClients() {
      let listL = [];
      this.todaysShifts.sort((a, b) => a.shiftNumber - b.shiftNumber);
      forEach(this.todaysShifts, (shift) => {
        const shiftName = bfhTypeServices.includes(this.service)
          ? bfhShiftsToTxt[shift.shiftNumber]
          : nbhShiftsToTxt[shift.shiftNumber];
        listL.push({
          type: "shift-title",
          data: shiftName,
          id: shiftName,
        });
        forEach(shift.shiftsList, (shiftListItem) => {
          const dataItem = !isNil(shiftListItem.clientData)
            ? shiftListItem.clientData
            : !isNil(shiftListItem.residenceData)
            ? shiftListItem.residenceData
            : {};
          const name = dataItem.clientName || dataItem.name || "";
          listL.push({
            type: "client-title",
            data: name,
            id: shift.shiftNumber + "-" + dataItem.id,
          });
          if (isEmpty(shiftListItem.shiftTasks)) {
            listL.push({
              type: "no-tasks",
              id: `${shift.shiftNumber}-${dataItem.id}-empty`,
            });
          }
          let completedTasksCount = 0;

          //START Get repositioning tasks
          let repositioningTasks = filter(shiftListItem.shiftTasks, (task) =>
            isEqual(task.lpTaskId, repositioningTaskId)
          );
          forEach(repositioningTasks, (task) => {
            const hours = task.order.substring(0, 2);
            const minutes = task.order.substring(3, 5);
            const [day, month, year] = split(task.lpTaskFormatDate, ".");
            const taskCreatedDate = new Date(
              year,
              month - 1,
              day,
              hours,
              minutes
            );
            task.taskCreatedDate = taskCreatedDate;
          });
          repositioningTasks.sort(function (a, b) {
            return new Date(a.taskCreatedDate) - new Date(b.taskCreatedDate);
          });
          //END Get repositioning tasks
          const regularTasks = filter(
            shiftListItem.shiftTasks,
            (task) => !isEqual(task.lpTaskId, repositioningTaskId)
          );
          regularTasks.sort(function (a, b) {
            let [aHoursString, aMinutesString] = a.order.split(":");
            let [bHoursString, bMinutesString] = b.order.split(":");
            let aMinutes =
              parseInt(aHoursString) * 60 + parseInt(aMinutesString);
            let bMinutes =
              parseInt(bHoursString) * 60 + parseInt(bMinutesString);
            return aMinutes - bMinutes;
          });
          const allTasksList = concat(repositioningTasks, regularTasks);
          forEach(allTasksList, (task) => {
            if (task.status !== "open") {
              completedTasksCount = completedTasksCount + 1;
            }
          });
          if (
            isEqual(allTasksList.length, completedTasksCount) &&
            !this.showAlsoCompletedTasks &&
            allTasksList.length > 0
          ) {
            listL.push({
              type: "all-tasks-completed",
              id: `${shift.shiftNumber}-${dataItem.id}-completed`,
            });
          } else {
            forEach(allTasksList, (task) => {
              if (isEqual(task.status, "open") || this.showAlsoCompletedTasks) {
                listL.push({
                  type: "task",
                  data: task,
                  id: task.id,
                  userData: this.userData || null,
                  taskDescription: task.taskDescription || null,
                  viewDialog: false,
                  shiftName: nbhShiftsToTxt[shift.shiftNumber],
                });
              }
            });
          }
        });
      });
      this.taskItemList = listL;
    },
    async getUserInfo() {
      let userResponse = await db
        .collection("users")
        .doc(this.selectedUser.userId)
        .get();
      if (userResponse.exists) {
        this.userData = userResponse.data();
      }
    },
  },
};
</script>

<style></style>
